.datapivotComponent {
    .datePickers {
        justify-content: left;

        & > * {
            margin: 20px;
        }

    }

    .active {
        background-color: #b6d7a8 !important;
    }

    .timeRangeShortcuts {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;

        & > * {
            margin: 5px;
        }

    }

    .seeMore {
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 30px;

        &.expanded {
            transform: rotate(180deg);

        }

    }

    .pivotSetting {
        margin-top: 10px;

        .pivotHeader {
            font-size: 1.5rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.334;
            letter-spacing: 0em;
        }

        .empSelector {
            // display: grid;
            // grid-template-columns: 400px auto;
            // column-gap: 20px;

            .empList {
                width: 100%;
                // grid-column-start: 1;
                // display: grid;
                // grid-template-rows: 30px auto;

                h4 {
                    grid-row-start: 1;
                }

                .list {
                    border: 1px solid #333;
                    overflow-y: auto;
                    grid-row-start: 2;

                    .selected {
                        background-color: #b6d7a8;
                    }

                }
            }

            .selectedListSection {
                grid-column-start: 2;
                display: grid;
                grid-template-rows: 30px auto;

                h4 {
                    grid-row-start: 1;
                }

                .selectedList {
                    grid-row-start: 2;
                    border: 1px solid #333;

                    .chip {
                        margin: 10px;
                    }
                }
            }


        }




    }

}
