$spacing: 20px;
$hovercolor: #eee;


.PivotDrawerContainer {
    width: 400px;

    .overviewPivotDrawer {
        position: absolute;
        top: 64px;
        bottom: 0px;
        overflow-y: auto;
        left: 0px;
        right: 0px;
    }

    .textblock {
        padding: 10px;
    }
}


.overviewModule {
    padding: $spacing;

    .FilterButtons {
        color: #000;
    }

    .overview {
        position: relative;
        display: inline-grid;
        width: 100%;

        margin-top: $spacing;

        max-width: 100%;
        max-height: 100%;

        grid-template-columns: 200px 200px 1px auto;
        grid-template-rows: 200px 200px 100px;
        grid-template-areas:
            "b b z f"
            "b b z f"
            "c d z f"
        ;
        column-gap: $spacing;
        row-gap: 10px;

        .vdiv {
            grid-area: z;
            width: 1px;
            height: 100%;
            background-color: #aaa;
        }

        .chart {
            position: relative;
            grid-area: b;

            .apexcharts-slices {
                text {
                    display: none;
                }
            }

            .score {
                font-weight: 100;
                text-align: center;
                position: relative;
                top: -56%;
                width: 170px;
                margin: auto;
                font-size: 16px;

                .metric {
                    font-size: 4em;
                }
            }
        }

        .explain {
            position: relative;
            font-weight: 100;
            grid-area: c;
            text-align: justify;
            text-justify: inter-word;
        }

        .revenue {
            position: relative;
            font-weight: 100;
            grid-area: d;
            text-align: right;



            .metric {
                font-size: 4em;
            }
        }

        .actions {
            position: relative;
            grid-area: f;
            //  background-color: #eee;

            .recommendations {
                height: 460px;
                overflow-y: auto;

                .Recommendation {
                    display: grid;
                    grid-template-columns: 50px auto 110px;
                    margin-bottom: 20px;
                    column-gap: $spacing;
                    cursor: pointer;
                    
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;

                    transition: background-color 0.3s ease-out;

                    background-color: #fefefe;

                    &:hover{background-color: $hovercolor}

                    .instanceCount {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #000;

                    }

                    h3 {
                        // border-left: 4px solid #000;
                        padding-left: 10px;
                    }

                    .desc {
                        padding-left: 14px;
                        // border-left: 1px solid #000;
                    }

                    .icon {
                        grid-column-start: 1;
                        position: relative;

                        .recIcon {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);

                        }
                    }

                    .type {
                        grid-column-start: 2;
                    }

                    .opportunity {
                        grid-column-start: 3;
                        text-align: right;
                        position: relative;
                        border-left: 1px dotted #000;

                        .metric {
                            position: absolute;
                            top: 50%;
                            right: 0px;
                            transform: translateY(-50%);
                            font-size: 2em;
                            font-weight: 100;
                        }

                    }
                }
            }

        }

        .pivot {
            position: relative;
            text-align: right;
            font-weight: 100;
            grid-area: e;

            h2 {
                font-weight: 100;
                margin: 0px;
            }
        }


    }

    .data {
        margin-top: 2em;
        width: 100%;
        height: 550px;

        .title {
            border-bottom: 1px solid #888;
        }
    }

}
