@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,600&display=swap');
$primary-color: #666;
$expanded-width: 200px;
$collapsed-width: 50px;



@mixin makeCircle {
   display: inline-block;
   border-radius: 50%;
   overflow: hidden;
}


.menu {
   background-color: $primary-color;
   height: 100%;
   color: #fff;
   float: left;
   min-width: $expanded-width;
   position: relative;
   display: inline-grid;
   grid-template-rows: [logoArea] 80px [linksArea] auto [logoutItem] 75px [personArea] 80px;
   box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
   border-right: 1px solid #ddd;
   overflow: hidden;
   transition: all 0.3s ease-out;

   &.collapsed {
      width: $collapsed-width;
      min-width: $collapsed-width;
      background-color: #333;
      margin-right: 50px;

      .logo {
         .circle {
            color: #333;
         }

      }

      .menuLink {
         width: $collapsed-width;

         .label {
            opacity: 0;
         }

         .icon {
            background-color: #888;
            padding-top: 2px;
            font-size: 14px;

            margin-top: -1px;
            height: 35px;

            &:hover {
               box-shadow: 0px 0px 15px 0px black;
               z-index: 10;
            }

         }
      }

      .menuLinks {
         max-width: $collapsed-width;

         // https://coolors.co/9f86c0-294c60-161b33-724cf9-564592
         .menuLink:nth-of-type(1) {
            .icon {
               background-color: #9F86C0;

               &:hover {
                  background-color: #abcdef;
               }
            }
         }

         .menuLink:nth-of-type(2) {
            .icon {
               background-color: #294C60;

               &:hover {
                  background-color: #abcdef;
               }
            }
         }

         .menuLink:nth-of-type(3) {
            .icon {
               background-color: #161B33;

               &:hover {
                  background-color: #abcdef;
               }
            }
         }

         .menuLink:nth-of-type(4) {
            .icon {
               background-color: #724CF9;

               &:hover {
                  background-color: #abcdef;
               }
            }
         }

         .menuLink:nth-of-type(5) {
            .icon {
               background-color: #564592;

               &:hover {
                  background-color: #abcdef;
               }
            }
         }

      }

      .avatarBadge {
         margin-left: -10px;
         border-radius: 0;
         margin-top: -11px;
         height: 78px;
         width: 48px;
         opacity: 0.3;
         border: 1px solid transparent;
      }
   }

   &.expanded {
      width: $expanded-width;

      .menuLink {
         .label {
            opacity: 1;
         }
      }
   }


   .logo {
      margin-top: 1em;
      margin-bottom: 2em;
      line-height: 1.8em;
      grid-row-start: logoArea;
      width: $expanded-width;
      font-size: 18px;
      font-weight: 100;
      cursor: pointer;


      .circle {
         @include makeCircle;
         position: relative;
         top: 7px;
         width: 30px;
         height: 30px;
         font-size: 25px;
         text-align: center;
         line-height: 25px;
         border-radius: 50%;
         background-color: #fff;
         color: $primary-color;
         margin-right: 13px;
         margin-left: 10px;

         transition: color 0.3s ease-out;

         &:hover {
            color: #b6d7a8;
         }

         svg {
            margin-top: 4px;
            margin-right: 4px;
         }
      }
   }

   .menuLinks {
      overflow: auto;
      overflow-x: hidden;
      grid-row-start: linksArea;
      transition: max-width 0.3s ease-out;
      max-width: $expanded-width;
      position: relative;
   }

   .menuLink {
      display: block;
      position: relative;

      height: 35px;
      padding: 10px;
      width: 100%;

      border-top: 1px solid #777;
      border-bottom: 1px solid #444;

      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 100;

      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
         background-color: #abcdef;


         .icon {
            background-color: #abcdef;

         }
      }

      .label {
         transition: opacity 0.3s ease-out;
         opacity: 1;
         white-space: nowrap;
         font-size: 12px;
      }

      .icon {
         position: absolute;
         right: 0px;
         top: 0px;

         display: block;
         float: right;

         width: 50px;

         margin-top: 0px;

         background-color: $primary-color;
         transition: background-color 0.3s linear;
         text-align: center;
         padding-top: 4px;
         height: 33px;

         font-size: 12px;

         .iconHolder {
            font-size: 2em;
         }

      }
   }

   .personFooter {
      position: relative;
      grid-row-start: personArea;
      display: inline-grid;
      background-color: #777;
      bottom: 0px;
      padding: 10px;
      border-top: 1px solid #888;

      grid-template-columns: 33% auto;
      grid-template-rows: auto;
      grid-template-areas:
         "a b"
         "a c"
      ;

      .avatarArea {
         grid-area: a;

         .avatarBadge {
            transition: all 0.3s ease-out;
         }
      }

      .nameArea {
         grid-area: b;
         padding-left: 1em;

         .name {
            font-size: 10px;
            font-weight: 800;
         }

         .title {
            font-size: 10px;
         }
      }

      .actions {
         grid-area: c;
         font-size: 10px;
         text-align: right;
      }



   }
}

.icon:nth-of-type(2) {
   background-color: purple;
}
