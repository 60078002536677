.actionlistModule {
    padding: 1em;
    display: grid;

    grid-template-areas:
        "a a"
        "z z"
        "b c"
    ;
    grid-template-columns: 300px auto;
    grid-template-rows: 80px fit-content(800px) auto;
    column-gap: 0px;
    row-gap: 10px;

    height: 100%;

    .header {
        grid-area: a;
        // background-color: #aaa;
        padding-top: 8px;
        padding-left: 8px;
    }

    .config {
        grid-area: z;
        // background-color: #aaa;
        padding-left: 8px;

    }

    .navigator {
        grid-area: b;
        border-right: 1px solid #aaa;

        .badge {
          color: #000;
        }

    }

    .content {
        grid-area: c;
        overflow: auto;
        // border-top: 1px solid #000;

    }

}
