.loginScreen {
    width: 100%;
    height: 100%;
    background-color: #b6d7a8;
    // margin-top: -20px;
    display: grid;
    grid-template-columns: auto fit-content(800px) auto;
    grid-template-rows: auto fit-content(800px) auto;
    grid-template-areas:
        ". . ."
        ". a ."
        ". . ."
    ;

    .background {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 50%;
        background-color: #fff;
        background-size: cover;
        box-shadow: inset 0px -15px 70px #000;

        // filter: blur(4px);
        // -o-filter: blur(4px);
        // -ms-filter: blur(4px);
        // -moz-filter: blur(4px);
        // -webkit-filter: blur(4px);
    }


    .box {
        grid-area: a;
        z-index: 1000;

        background: rgba(0, 0, 0, 0.8);



        .container {
            text-align: center;

            .name {
                font-size: 30px;
                font-weight: 100;
                padding-bottom: 15px;
            }

            .section {
                display: inline-block;

                .logo {
                    width: 60px;
                    height: 60px;
                    margin: 20px;
                    background-color: #b6d7a8;

                    svg {
                        font-size: 42px;
                        position: relative;
                        top: 3px;
                        right: 2px;
                    }
                }

                form {
                    .MuiTextField-root {
                        width: 100%;
                    }

                    .rememberForgot {
                        text-align: right;
                        width: 300px;
                        padding: 10px;

                        a {
                            position: relative;
                            top: 1px;

                        }

                    }

                }
            }

            .buttons {
                padding: 10px;

                button {
                    width: 100%;
                    background-color: #b6d7a8;
                }
            }


        }


    }

}
