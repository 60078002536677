.container {
	height: 100%;
	width: 100%;
	display: inline-block;

	.contentpane {
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		background-color: #fff;
		.contentArea {
			background-color: #fff;
			margin-left: 20px;
			min-height: 100%;
			height: 100%;
		}
	}
}