html, body, #root {
    margin: 0px;
    height: 100%;
    font-family: 'Heebo', "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    background-color: #fff;
}

#__DEBUG__ {
    position: fixed;
    bottom: 5px;
    right: 5px;
    height: 300px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 10000;
    overflow: auto;
}

// Typography
h1 {
    font-weight: 100;
    font-size: 30px;
}

h2 {
    font-weight: 100;
    margin: 0px;
    font-size: 22px;
}

h4 {
    display: block;
    margin-block-start: 5px;
    margin-block-end: 5px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 100;
    border-left: 3px solid #000;
    padding-left: 10px;
}

// COLORS
.FF928B {
    background-color: #FF928B !important;
}

.FFAC81 {
    background-color: #FFAC81 !important;
}

.FEC3A6 {
    background-color: #FEC3A6 !important;
}

.EFE9AE {
    background-color: #EFE9AE !important;
}

.CDEAC0 {
    background-color: #CDEAC0 !important;
}
